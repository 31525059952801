
import { Component, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import FullScreenForm from '@/components/FullScreenForm.vue';
import RegistrationFormRenderer from '@/components/RegistrationFormRenderer.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { addAnalyticsRouteParams } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';
import { openSnackbar } from '@/utils/components';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import { SelectOption } from '@/components/form/form.types';
import FormWizard from '@/components/form/FormWizard.vue';
import PreventBack from '@/components/form/PreventBack.vue';
import PromoteProd from '@/views/PromoteToProd.vue';
import { AUTH_SRC, Environments, RouteNames } from '@/constants';
import { ApiPromotionFormData, Project, ProjectService } from '@/types/projects.types';
import { loadRegistrationFormRenderer } from '@/api/admin-registration.api';
import { DynamicRegFormModule } from '@/store/modules/dynamic-registration.module';
import {getApiRegistration, registerApi} from '@/api/registration.api';
import { getServiceNowIds, ProjectEnvironmentPromotion, Projects } from '@/api/projects.api';
import { get } from 'lodash';
import { DeckConfig } from '@/components/form/dynamic-form.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { AppEnum } from '@/types/enum.types';
import {FeatureFlagsModule} from "@/store/modules/feature-flags.module";
import { checkProxyPathUniqueness } from '@/utils/dynamic-registration-validation';
import { normalizeUrlPath } from '@/views/api-registration/registration.helpers';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';


@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GAnalytics,
    GRadioGroup,
    FormWizard,
    PreventBack,
    PromoteProd,
    RegistrationFormRenderer,
    GDialog
  },
})
export default class PromoteProjectView extends Vue {

  public loading = true;
  public preventExitDisabled = true;
  public promoteStepArray: any[] = [];
  public currentStepIndex = -1;
  showProd = false;
  $refs!: {
    regFormRendererRef: RegistrationFormRenderer
  }
  public openUnavailableDialog = false;

  public showFields = [
    "upstreamUrl",
    "upstreamUrlFieldContainer",
    "request-transformer-headers",
    "customHeadersFieldContainer",
    "request-transformer-headers-queryparams",
    "customQueryParamsFieldContainer",
    "ciid",
    "ciidFieldContainer",
    "smartControlsHeader",
    "hasSmartControls",
    "hasWAST",
  ];

  formRendererData = '';
  loadingRegistration = false;
  isNextDisabled = false;
  validatingData = false;

  async getPandoraIdChoices(query: string): Promise<Projects.ServiceNowIdLabel[]> {
    const choices = await getServiceNowIds(query);
    return choices.data.map(res => ({ label: `${res.id} - ${res.name}`, value: res.id }));
  }

  get simpleMode() {
    return DynamicRegFormModule.simpleMode;
  }

  get deckConfig() {
    return DynamicRegFormModule.deckConfig;
  }

  get projectIds() {
    return {
      projectId: +this.$route.params.id,
      projectEnvId: +this.$route.params.env,
    };
  }

  get projectEnv() {
    return ProjectDetailsModule.envs[this.projectIds.projectId]?.[this.projectIds.projectEnvId];
  }

  get allServices() {
    return this.projectEnv?.registrations?.services?.flat() ?? [];
  }

  get deckServiceData() {
    return get(this.promoteStepArray[this.currentStepIndex - 1], 'deck.services[0]', '');
  }

  get serviceNowId() {
    return this.deckServiceData.customProps?.serviceNowId;
  }

  get customHeaders() {
    return this.deckServiceData.customProps?.customHeaders
  }

  get customQueryParams() {
    return this.deckServiceData.customProps?.customQueryParams
  }

  regs = '';
  get registrationOptions(): SelectOption[] {
    return this.allServices
      .filter(service => service.canPromote)
      .map(service => {
        return {
          value: service.serviceId.toString(),
          label: `${service.resourceName} v${service.version}`,
        };
      });
  }

  get closeRoute() {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: addAnalyticsRouteParams(
        {
          id: this.projectIds.projectId.toString(),
          env: this.projectIds.projectEnvId.toString(),
        },
        {
          projectName: this.projectName,
          projectId: this.project.projectId,
        },
      ),
    };
  }

  get project(): DeepReadonly<Project> {
    return ProjectDetailsModule.projectDetails;
  }

  get projectName(): string {
    return this.project.projectName;
  }

  get projectDescription(): string {
    return this.project.projectDescription;
  }

  get oauthMnemonic() {
    const projectId = (this.$route.params && +this.$route.params.id) || undefined;
    const projectEnvId = +this.$route.params.env;
    if (projectId) {
      if (
        ProjectDetailsModule.oAuthMneumonics[projectId] &&
        ProjectDetailsModule.oAuthMneumonics[projectId][projectEnvId]?.oAuthType
      ) {
        return ProjectDetailsModule.oAuthMneumonics[projectId][projectEnvId]?.oAuthType;
      } else if (ProjectDetailsModule.envs[projectId][projectEnvId]?.auth.OAuth.oauthType) {
        return ProjectDetailsModule.envs[projectId][projectEnvId]?.auth.OAuth.oauthType;
      }
    }
    return AUTH_SRC.PING_OAUTH.mnemonic;
  }

  get serviceId() {
    return this.regs;
  }

  get platformMnemonic() {
    return ProjectDetailsModule.projectDetails.platform?.mnemonic;
  }

  async created() {
    this.loadingRegistration = true;
    const response = await loadRegistrationFormRenderer();
    this.loadingRegistration = false;
    this.formRendererData = response.data;
    DynamicRegFormModule.setShowFieldsWithNames(this.showFields)
    const { projectId, projectEnvId } = this.projectIds;
    const fromEnv = this.$store.state?.route?.from?.name === RouteNames.ProjectEnvDetails;
    if (!fromEnv) {
      const projP = ProjectDetailsModule.getProject(projectId);
      // - project env: status, registrations
      const regsP = ProjectDetailsModule.getProjectEnvironmentPart({
        projectEnvId,
        projectId,
        partName: 'registrations',
      });
      const statusP = ProjectDetailsModule.getProjectEnvironmentPart({
        projectEnvId,
        projectId,
        partName: 'status',
      });
      try {
        await Promise.all([projP, regsP, statusP]);
      } catch (e) {
        openSnackbar.call(this, 'Server Error: Could not load data', { type: 'error' });
        this.preventExitDisabled = true;
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
    // await ProjectDetailsModule.allProxyPath(this.platformMnemonic);
    if (this.isQa && !this.hasRegistrationOptions) {
      this.showProd = true;
    }
  }

  onValidChange($event: boolean) {
    this.isNextDisabled = !$event;
  }

  onValidating($event: boolean) {
    this.validatingData = $event;
  }

  async loadForm(res: any) {
    const services = get(res.data, 'deck.services', []);
    const [service] = services;
    DynamicRegFormModule.setResourceData({
      name: service?.customProps?.resourceName,
      path: service?.routes?.[0]?.paths?.[0],
      isEdit: false,
      isPromote: true,
      versions: [],
    });
    DynamicRegFormModule.setSimpleMode(true);
    DynamicRegFormModule.setDeckConfig(res.data.deck);
    await DynamicRegFormModule.setOAuthMnemonicVal(this.oauthMnemonic);
    DynamicRegFormModule.updateRegFormData();
    this.$refs.regFormRendererRef.asyncValidate();
  }

  async navigateToNextStep() {
    if (this.regs.length && FeatureFlagsModule.registrationEnabled) {
      this.currentStepIndex = this.currentStepIndex + 1;
      this.loading = true;
      if (FeatureFlagsModule.budgetingSourceEnabled) {
        const ifBudgetingSourceExists = await this.isBudgetingSourceExists(this.nextEnvName.toUpperCase(),ProjectDetailsModule.projectDetails?.budgetingSource)
        if(!ifBudgetingSourceExists) {
          this.currentStepIndex = this.currentStepIndex - 1;
        }
      }
      const res: any = await getApiRegistration(this.serviceId);
      const services = get(res.data, 'deck.services', []);
      const [service] = services;
      const path = service?.routes?.[0]?.paths?.[0];
      const ifProxyPathExists = await checkProxyPathUniqueness(
        normalizeUrlPath(path, true),
        undefined,
        this.nextEnvName.toUpperCase(),
      );
      if(!ifProxyPathExists) {
        openSnackbar.call(this, 'Proxy path ' + path + ' is already in use by another API.', {
          type: 'error',
        });
        this.currentStepIndex = this.currentStepIndex - 1;
      }
      this.loading = false;
      this.loadForm(res);
    } else {
      // user selected no items
      if (this.isDev) {
        this.submitForm();
      } else {
        this.showProd = true;
        // going to prod, show the form for the rest
      }
    }
  }

  serviceData(service: ProjectService | undefined, deckConfig: DeckConfig) {
    const [deckservice] = deckConfig.services;
    const url = `${deckservice.protocol}://${deckservice.host}${deckservice.path}`;
    const customHeaders = deckservice.customProps.customHeaders;
    const customQueryParams = deckservice.customProps.customQueryParams;
    const ciid: number = deckservice.customProps.serviceNowId;
    const smartControlsCompleted = deckservice.customProps.smartControlsCompleted;
    const wastTestCompleted = deckservice.customProps.wastTestCompleted;

    if (service !== undefined) {
      let servicePayload = {} as ApiPromotionFormData;

      servicePayload = {
        serviceId: service.serviceId,
        requiresNotification: service.requiresNotification,
        requiresApproval: service.requiresApproval,
        isControlsCompliant: service.isControlsCompliant,
        smartControlsCompleted: smartControlsCompleted || false,
        wastTestComplete: wastTestCompleted || false,
        authenticationType: service.authenticationType,
        proxyPath: get(service, 'serviceProperties.kong_proxy_path.0'),
        upstreamUrl: url,
        timeoutMs: service.serviceProperties.timeout_ms?.toString() || '',
        isCustom: service.isCustom,
        customNotes: service.customNotes || '',
        headerName: service.headerName || '',
        headerValue: service.headerValue || '',
        headers: customHeaders || [],
        healthCheckPath: service.healthCheckPath,
        healthCheckPattern: service.healthCheckPattern,
        upstreamQueryString: customQueryParams || [],
        httpMethods: get(service, 'serviceProperties.httpMethods',[]),
        corsSupport: get(service, 'serviceProperties.corsSupport', false),
        requestPayloadSize: get(service, 'requestPayloadSize', null),
        requestPayloadUnit: get(service, 'requestPayloadUnit', null),
        requestPayloadBusinessJustification: get(service, 'requestPayloadBusinessJustification', null),
        enabledIpRestrictionPlugin: service.serviceProperties.enabled_ip_restriction_plugin,
        allowedIpList: service.serviceProperties.allowed_ip_list || [],
        apiKeyIdentifier: service.serviceProperties.kong_keyauth_template_apikeyidentifier,
        serviceNowId: ciid.toString(),
      };
      return [servicePayload].filter(service => service);
    }
    return [];
  }

  get hasRegistrationOptions(): boolean {
    return !!this.registrationOptions.length;
  }

  get nextEnvName() {
    if (this.isDev) {
      return EnumsModule.enums.environment.QA.name;
    }
    return EnumsModule.enums.environment.PROD.name;
  }

  get isDev() {
    return this.envEnum?.mnemonic === 'DEV';
  }

  get isQa() {
    return this.envEnum?.mnemonic === 'QA';
  }

  get envEnum(): AppEnum | undefined {
    const envId =
      ProjectDetailsModule.projectEnvsByProjectEnvId[this.projectIds.projectEnvId]?.environmentId;
    return EnumsModule.enumsById.environment[envId];
  }

  promote(payload: any) {
    if (this.isDev) {
      this.handlePromotion(
        ProjectDetailsModule.promoteEnvironment({
          projectEnvId: this.projectIds.projectEnvId,
          body: payload,
          srcEnv: Environments.Dev,
        }),
      );
    } else {
      this.showProd = true;
    }
  }

  handlePromotion(p: Promise<ProjectEnvironmentPromotion>) {
    this.loading = true;
    return p
      .then(res => {
        this.preventExitDisabled = true;
        this.$router.replace({
          name: RouteNames.ProjectEnvDetails,
          params: {
            env: res.projectEnvironmentId.toString(),
            id: this.projectIds.projectId.toString(),
            section: 'auth',
          },
        });
      })
      .catch(() => {
        openSnackbar.call(this, 'Error: Could not promote environment', { type: 'error' });
      })
      .finally(() => (this.loading = false));
  }

  goBackToList() {
    this.regs = '';
    this.currentStepIndex = -1;
  }

  prodBack() {
    if (this.hasRegistrationOptions) {
      // check this later
      this.goBackToList();
      this.showProd = false;
    } else {
      this.$router.replace(this.closeRoute);
    }
  }

  async submitForm() {
    const service = this.allServices.find((service) => service.serviceId.toString() === this.serviceId);
    const { deckConfig } = this;
    const payload: { services: any[]; deck: DeckConfig | null } = {
      services: service ? this.serviceData(service, deckConfig) : [],
      deck: null,
    };

    if (payload.services.length && deckConfig && 'services' in deckConfig && deckConfig.services.length > 0) {
      payload.deck = deckConfig;
    }

    if(payload) {
      this.promote(payload);
    }
  }

  get servicesDataForPromote() {
    if (this.regs.length) {
      const service = this.allServices.find((service) => service.serviceId.toString() === this.serviceId);
      return this.serviceData(service, this.deckConfig);
    }
    return [];
  }

  navigateToPreviousStep() {
    this.currentStepIndex = -1;
  }

  get budgetingSourcePromoteMessage() {
    return ProcessEnvsModule.processEnvs.budgetingSourcePromoteText;
  }

  async isBudgetingSourceExists(nextEnv: string,budgetingSource?: Projects.BudgetingSource,){
    if(nextEnv === "PROD"){
      if(!budgetingSource){
        this.openUnavailableDialog = true;
        return false;
      }
    }
    return true;
  }
}
